module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chefs á Porter","short_name":"Chefs á Porter","title":"Best Curated Dining experience in Bangalore - Chefs á Porter","description":"Chef's Tables™ : Immersive, intimate, & conscious fine-dining experiences for the epicurious.","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"standalone","icon":"src/assets/images/Logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e916d2f8698b29cc02e903814b5d4821"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
