exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-addons-booking-id-js": () => import("./../../../src/pages/addons/[bookingId].js" /* webpackChunkName: "component---src-pages-addons-booking-id-js" */),
  "component---src-pages-become-a-patron-index-js": () => import("./../../../src/pages/become-a-patron/index.js" /* webpackChunkName: "component---src-pages-become-a-patron-index-js" */),
  "component---src-pages-become-partner-index-js": () => import("./../../../src/pages/become-partner/index.js" /* webpackChunkName: "component---src-pages-become-partner-index-js" */),
  "component---src-pages-become-patron-index-js": () => import("./../../../src/pages/become-patron/index.js" /* webpackChunkName: "component---src-pages-become-patron-index-js" */),
  "component---src-pages-booking-dinerdetails-booking-id-js": () => import("./../../../src/pages/booking/dinerdetails/[bookingId].js" /* webpackChunkName: "component---src-pages-booking-dinerdetails-booking-id-js" */),
  "component---src-pages-booking-fillhostdetails-booking-id-js": () => import("./../../../src/pages/booking/fillhostdetails/[bookingId].js" /* webpackChunkName: "component---src-pages-booking-fillhostdetails-booking-id-js" */),
  "component---src-pages-booking-summary-summary-booking-id-js": () => import("./../../../src/pages/booking-summary/[summaryBookingId].js" /* webpackChunkName: "component---src-pages-booking-summary-summary-booking-id-js" */),
  "component---src-pages-cancellation-policy-index-js": () => import("./../../../src/pages/cancellation-policy/index.js" /* webpackChunkName: "component---src-pages-cancellation-policy-index-js" */),
  "component---src-pages-chef-details-[id]-jsx": () => import("./../../../src/pages/chef-details/[id].jsx" /* webpackChunkName: "component---src-pages-chef-details-[id]-jsx" */),
  "component---src-pages-contact-permission-index-js": () => import("./../../../src/pages/contact-permission/index.js" /* webpackChunkName: "component---src-pages-contact-permission-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-corporate-booking-index-js": () => import("./../../../src/pages/corporate-booking/index.js" /* webpackChunkName: "component---src-pages-corporate-booking-index-js" */),
  "component---src-pages-customer-details-index-js": () => import("./../../../src/pages/customer-details/index.js" /* webpackChunkName: "component---src-pages-customer-details-index-js" */),
  "component---src-pages-event-details-event-id-js": () => import("./../../../src/pages/event-details/[eventId].js" /* webpackChunkName: "component---src-pages-event-details-event-id-js" */),
  "component---src-pages-getin-touch-popup-index-js": () => import("./../../../src/pages/getin-touch-popup/index.js" /* webpackChunkName: "component---src-pages-getin-touch-popup-index-js" */),
  "component---src-pages-gift-card-sent-index-js": () => import("./../../../src/pages/gift-card-sent/index.js" /* webpackChunkName: "component---src-pages-gift-card-sent-index-js" */),
  "component---src-pages-gift-cards-index-js": () => import("./../../../src/pages/gift-cards/index.js" /* webpackChunkName: "component---src-pages-gift-cards-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-chef-index-js": () => import("./../../../src/pages/join-chef/index.js" /* webpackChunkName: "component---src-pages-join-chef-index-js" */),
  "component---src-pages-mobile-contact-index-js": () => import("./../../../src/pages/mobile-contact/index.js" /* webpackChunkName: "component---src-pages-mobile-contact-index-js" */),
  "component---src-pages-mobile-gift-card-sent-index-js": () => import("./../../../src/pages/mobile-gift-card-sent/index.js" /* webpackChunkName: "component---src-pages-mobile-gift-card-sent-index-js" */),
  "component---src-pages-mobile-payment-index-js": () => import("./../../../src/pages/mobile-payment/index.js" /* webpackChunkName: "component---src-pages-mobile-payment-index-js" */),
  "component---src-pages-our-chefs-index-js": () => import("./../../../src/pages/our-chefs/index.js" /* webpackChunkName: "component---src-pages-our-chefs-index-js" */),
  "component---src-pages-patron-app-submit-index-js": () => import("./../../../src/pages/patron-app-submit/index.js" /* webpackChunkName: "component---src-pages-patron-app-submit-index-js" */),
  "component---src-pages-personal-details-index-js": () => import("./../../../src/pages/personal-details/index.js" /* webpackChunkName: "component---src-pages-personal-details-index-js" */),
  "component---src-pages-private-index-js": () => import("./../../../src/pages/private/index.js" /* webpackChunkName: "component---src-pages-private-index-js" */),
  "component---src-pages-private-viewmore-index-js": () => import("./../../../src/pages/private-viewmore/index.js" /* webpackChunkName: "component---src-pages-private-viewmore-index-js" */),
  "component---src-pages-ticketed-booking-confirm-supper-club-confirm-booking-id-js": () => import("./../../../src/pages/ticketed-booking-confirm/[supperClubConfirmBookingId].js" /* webpackChunkName: "component---src-pages-ticketed-booking-confirm-supper-club-confirm-booking-id-js" */),
  "component---src-pages-ticketed-booking-summary-supper-club-booking-id-js": () => import("./../../../src/pages/ticketed-booking-summary/[supperClubBookingId].js" /* webpackChunkName: "component---src-pages-ticketed-booking-summary-supper-club-booking-id-js" */),
  "component---src-pages-ticketed-detail-supper-club-detail-id-js": () => import("./../../../src/pages/ticketed-detail/[supperClubDetailId].js" /* webpackChunkName: "component---src-pages-ticketed-detail-supper-club-detail-id-js" */),
  "component---src-pages-ticketed-index-js": () => import("./../../../src/pages/ticketed/index.js" /* webpackChunkName: "component---src-pages-ticketed-index-js" */),
  "component---src-templates-events-template-js": () => import("./../../../src/templates/EventsTemplate.js" /* webpackChunkName: "component---src-templates-events-template-js" */)
}

